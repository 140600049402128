import axios from "axios";

const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
const userData =  JSON.parse(localStorage?.getItem('user_data'));

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    "Authorization": `Bearer ${userData?.token || ''}`,
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const user =  JSON.parse(localStorage?.getItem('user_data'));
    // Check if the user is authenticated and has a token
    const token = user?.token || null;

    // Set the Authorization header if a token exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
