export const isAuthenticated = (state) => {
  if (state?.auth?.auth?.success) return true;
  return false;
};

export const isAdmin = (state) => {
  if (state?.auth?.auth?.users?.userType === "admin") {
    return true;
  }

  return false;
};
