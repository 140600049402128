import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function BannerUpdate() {
  const [apiData, setApiData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailure, setUploadFailure] = useState(false);

  const [manualInput, setManualInput] = useState({
    bannerName: '',
    Description: '',
    bannerImage: '',
    createdBy: '',
    modifiedBy: '',
  });

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.tinymealmakers.com/api/banner/get');
      setApiData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const uploadImage = async () => {
    try {
      if (!selectedFile) {
        console.error('No file selected for upload.');
        return;
      }

      const formData = new FormData();
      formData.append('image', selectedFile);

      const response = await axios.post('https://api.tinymealmakers.com/api/image/upload', formData);

      console.log('Image uploaded successfully:', response.data);

      setUploadSuccess(true);
      setUploadFailure(false);

      fetchData();
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadSuccess(false);
      
      setUploadFailure(true);
    }
  };

  const addBanner = async () => {
    try {
      const response = await axios.post('https://api.tinymealmakers.com/api/banner/add', manualInput);

      console.log('Banner added successfully:', response.data);

      setUploadSuccess(true);
      setUploadFailure(false);

      fetchData();
    } catch (error) {
      console.error('Error adding banner:', error);
      setUploadSuccess(false);
      setUploadFailure(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManualInput((prevInput) => ({ ...prevInput, [name]: value }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-6">
          {apiData && (
            <div className="mb-4">
              <h2 className="mb-3">Banner Data</h2>
              <ul className="list-group">
                {apiData.map((item) => (
                  <li key={item.id} className="list-group-item">
                    {item.title}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <form className="mb-4">
            <h2 className="mb-3">Add Banner</h2>
            <div className="mb-3">
              <label className="form-label">Banner Name</label>
              <input
                type="text"
                className="form-control"
                name="bannerName"
                value={manualInput.bannerName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                name="Description"
                value={manualInput.Description}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">Banner Image</label>
              <input
                type="text"
                className="form-control"
                name="bannerImage"
                value={manualInput.bannerImage}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Created By</label>
              <input
                type="text"
                className="form-control"
                name="createdBy"
                value={manualInput.createdBy}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Modified By</label>
              <input
                type="text"
                className="form-control"
                name="modifiedBy"
                value={manualInput.modifiedBy}
                onChange={handleInputChange}
              />
            </div>

            <button type="button" className="btn btn-primary" onClick={addBanner}>
              Add Banner
            </button>
          </form>

          <div className="mb-4">
            <h2 className="mb-3">Upload Image</h2>
            <input type="file" className="form-control" onChange={handleFileChange} />
            <button className="btn btn-primary mt-3" onClick={uploadImage}>
              Upload Image
            </button>
          </div>

          {uploadSuccess && (
            <div className="alert alert-success" role="alert">
              Operation successful!
            </div>
          )}

          {uploadFailure && (
            <div className="alert alert-danger" role="alert">
              Failed to upload. Please try again.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BannerUpdate;
