import React, { useContext } from "react";

/// React router dom
import { Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

import { ThemeContext } from "../context/ThemeContext";
import { useSelector } from "react-redux";

export function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  const user = useSelector((state) => state.auth.auth.user);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{
          minHeight: window.screen.height - 45,
          position: user ? "relative" : "unset",
        }}
      >
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

// export default Markup;
