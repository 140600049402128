export const MenuList = [
  //Dashboard
  {    
    title: "",
    // classsChange: "mm-collapse",
    iconStyle: "bi bi-grid",
    content: [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Package",
        to: "plan",
      },  
      {
        title: "Order History",
        to: "order-history",
      },
      // {
      //   title: "Food Order",
      //   to: "food-order",
      // },
    ],
  },
  //Restaurant
  // {
  //   title: "Restaurant",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Restaurant",
  //       to: "restaurant",
  //     },
  //     {
  //       title: "Orders",
  //       to: "orders",
  //     },
  //   ],
  // },
  //Subcription
  // {
  //   title: "Subcription",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-bicycle",
  //   content: [
  //     {
  //       title: "Dashboard",
  //       to: "deliver-main",
  //     },
  //     {
  //       title: "Plan",
  //       to: "plan",
  //     },
  //   ],
  // },
  //Admin Config
  {
    title: "Admin Config",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "Admin Panel",
        to: "restaurant",
      },
      {
        title: "Banner",
        to: "bannner",
      },
      {
        title: "User List",
        to: "user-list",
      },
      {
        title: "Delivery Partner",
        to: "deliver-Add",
      },
      {
        title: "Create Plans",
        hasMenu: true,
        content: [
          {
            title: "Foods",
            to: "food_table",
          },
          {
            title: "Combos",
            to: "food_combo",
          },
          {
            title: "Packages",
            to: "food_package",
          },
        ],
      },
    ],
  },
];
