import { lazy, Suspense, useEffect } from "react";

/// Components
import Index, { MainLayout } from "./jsx";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated, isAdmin } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import AddDeliveryPersonForm from "jsx/components/DeliveryModule/AddDelivery";
import OrderSummary from "jsx/components/Dashboard/Drivers/OrderSummary";
import BannerUpdate from "jsx/ImageUpload/BannerUpdate";

const Error404 = lazy(() => import("./jsx/pages/Error404"));
const LockScreen = lazy(() => import("./jsx/pages/LockScreen"));
const Home = lazy(() => import("./jsx/components/Dashboard/Home"));
const Restaurant = lazy(() =>
  import("./jsx/components/Dashboard/Restaurant/Restaurant")
);
const Orders = lazy(() =>
  import("./jsx/components/Dashboard/Restaurant/Orders")
);
const DeliverMain = lazy(() =>
  import("./jsx/components/Dashboard/Drivers/DeliverMain")
);
const DeliverOrder = lazy(() =>
  import("./jsx/components/Dashboard/Drivers/DeliverOrder")
);
const Packages = lazy(() => import("./jsx/components/Subcription/Packages"));
const SinglePackage = lazy(() =>
  import("./jsx/components/Subcription/SinglePackage")
);
const Location = lazy(() => import("./jsx/components/Subcription/Location"));
const AddressForm = lazy(() => import("./jsx/pages/Auth/AddressForm"));
const AppProfile = lazy(() =>
  import("./jsx/components/AppsMenu/AppProfile/AppProfile")
);
const CheckoutPage = lazy(() =>
  import("./jsx/components/Dashboard/CheckoutPage")
);
const MyPackage = lazy(() => import("./jsx/pages/Customer/MyPackage"));
const List = lazy(() => import("./jsx/components/AdminConfig/List"));
const FoodOrder = lazy(() => import("./jsx/components/Dashboard/FoodOrder"));
const OrderHistory = lazy(() =>
  import("./jsx/components/Dashboard/OrderHistory")
);
const Bill = lazy(() => import("./jsx/components/Dashboard/Bill"));
const UserList = lazy(() => import("./jsx/pages/Auth/UserList"));
const FoodPages = lazy(() => import("./jsx/pages/AdminConfigPages/foodpages"));
const ComboPages = lazy(() =>
  import("./jsx/pages/AdminConfigPages/combopages")
);
const PackagePages = lazy(() =>
  import("./jsx/pages/AdminConfigPages/packagespages")
);
const EnableTwoFactor = lazy(() => import("./jsx/pages/EnableTwoFactor"));
const FoodTable = lazy(() => import("./jsx/pages/FoodTable"));
const FoodCombo = lazy(() => import("./jsx/pages/FoodDetails/FoodCombo"));
const FoodPackage = lazy(() => import("./jsx/pages/FoodDetails/FoodPackage"));
const FoodUpdate = lazy(() => import("./jsx/pages/FoodUpdate"));
const HomeSetting = lazy(() => import("./jsx/components/Dashboard/Setting"));

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const EnterOTP = lazy(() => import("./jsx/pages/Auth/EnterOTP"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const AddAddress = lazy(() =>
  import("./jsx/components/Dashboard/Drivers/addAddress")
);

const authenticatedRoutes = [
  { url: "checkout", component: <CheckoutPage /> },
  { url: "setting", component: <HomeSetting /> },
  { url: "food-order", component: <FoodOrder /> },
  { url: "order-history", component: <OrderHistory /> },
  { url: "bill", component: <Bill /> },
  { url: "Add-Address", component: <AddAddress /> },
  { url: "orderSummary", component: <OrderSummary /> },
];

const adminRoutes = [
  { url: "user-list", component: <UserList /> },
  { url: "food", component: <FoodPages /> },
  { url: "combo", component: <ComboPages /> },
  { url: "add-package", component: <PackagePages /> },
  { url: "enable2fa", component: <EnableTwoFactor /> },
  { url: "food_table", component: <FoodTable /> },
  { url: "food_combo", component: <FoodCombo /> }, 
  { url: "food_update", component: <FoodUpdate /> },
  { url: "food_package", component: <FoodPackage /> },
  { url: "restaurant", component: <Restaurant /> },
  { url: "deliver-Add", component: <AddDeliveryPersonForm /> },
  { url: "bannner", component: <BannerUpdate /> },
];

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, []);

  const getRoutesBasedOnRole = () => {
    if (props.isAuthenticated && props.isAdmin) {
      return [...authenticatedRoutes, ...adminRoutes];
    } else if (props.isAuthenticated && !props.isAdmin) {
      return [...authenticatedRoutes];
    }
    return [];
  };

  const routes = getRoutesBasedOnRole();

  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce lds-ripple">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/page-register" element={<SignUp />} />
          <Route path="/page-forgot-password" element={<ForgotPassword />} />
          <Route path="/enter-otp" element={<EnterOTP />} />
          <Route path="page-lock-screen" element={<LockScreen />} />
          <Route path="/404" element={<Error404 />} />
          {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
          <Route element={<MainLayout />}>
            <Route path="" exact element={<Home />} />
            <Route path="orders" exact element={<Orders />} />
            <Route path="list" exact element={<List />} />
            <Route path="deliver-main" exact element={<DeliverMain />} />
            <Route path="deliver-order" exact element={<DeliverOrder />} />
            <Route path="plan" exact element={<Packages />} />
            <Route path="location" exact element={<Location />} />
            <Route path="address-form" exact element={<AddressForm />} />
            <Route path="app-profile" exact element={<AppProfile />} />
            <Route path="mypackage" exact element={<MyPackage />} />
            <Route path="package" exact element={<SinglePackage />} />
            {routes.map((data, i) => (
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isAdmin: isAdmin(state),
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
