import axios from "../utils/axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

export function signUp(email, password, firstname, lastname, phoneNumber) {
  //axios call
  const postData = {
    email,
    password,
    firstName: firstname,
    lastName: lastname,
    phoneNumber,
    userType: "customer",
    createdBy: "user",
    modifiedBy: "user",
  };
  return axios.post(`/api/user/register`, postData);
}

export function login(email, password) {
  const postData = {
    email,
    password,
    method: 'email'
  };
  return axios.post(`/api/user/login`, postData);
}

export function formatError(errorResponse) {
  if (errorResponse) {
    swal("Oops", errorResponse, "error");
  }
  // switch (errorResponse.error.message) {
  //     case 'EMAIL_EXISTS':
  //         //return 'Email already exists';
  //         swal("Oops", "Email already exists", "error");
  //         break;
  //     case 'EMAIL_NOT_FOUND':
  //         //return 'Email not found';
  //        swal("Oops", "Email not found", "error",{ button: "Try Again!",});
  //        break;
  //     case 'INVALID_PASSWORD':
  //         //return 'Invalid Password';
  //         swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
  //         break;
  //     case 'USER_DISABLED':
  //         return 'User Disabled';

  //     default:
  //         return '';
  // }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function saveDataInLocalStorage(data) {
  localStorage.setItem("user_data", JSON.stringify(data));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  if (user_data?.success) {
    dispatch(loginConfirmedAction(user_data));
  }
}
